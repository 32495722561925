<template>
  <div class="means">
    <PageTopNav
      :pageNavShow="false"
      :addShow="true"
      :tabList="tabList"
      @addMeans="addMeans"
      @changeTab="changeTab"
    >
      <p slot="addName">添加烘干计划</p>
    </PageTopNav>
    <div class="outDiv">
      <div class="mains">
        <div class="lists" v-if="meansList.length">
          <MyTable
            class="animated fadeInUp"
            :tableData="meansList"
            :configTable="configTable"
            @handleDelete="handleDelete"
            @handleEdit="handleEdit"
          ></MyTable>
        </div>
        <div class="lists" v-else>
          <MyNothing></MyNothing>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          v-if="meansList.length"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增/修改烘干计划 -->
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="666px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDialog()"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          <span>{{ bigPopTitle }}</span>
        </div>
        <div class="addForm">
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            hide-required-asterisk
          >
            <el-form-item
              label="名称"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input
                v-model.trim="form.name"
                autocomplete="off"
                placeholder="请输入名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="选择产品"
              :label-width="formLabelWidth"
              prop="product_id"
            >
              <el-select v-model="form.product_id" placeholder="请选择产品">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in productList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="生产数量"
              :label-width="formLabelWidth"
              prop="num"
            >
              <el-input
                type="number"
                v-model.number="form.num"
                placeholder="请输入计划生产数量"
                @input="inputNumber"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="备注"
              :label-width="formLabelWidth"
              prop="remark"
            >
              <el-input
                v-model.trim="form.remark"
                type="textarea"
                autocomplete="off"
                placeholder="请输入备注"
                maxlength="200"
              >
              </el-input>
            </el-form-item>
            <el-form-item label=" " class="dialog-footer">
              <el-button
                class="ok-button"
                type="primary"
                @click="okAdd('form')"
              >
                确 定
              </el-button>
              <el-button class="no-button" @click="noAdd('form')">
                取 消
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getDryPlanListApi,
  postEditDryPlanApi,
  postAddDryPlanApi,
  getProductListApi,
  delDryPlanApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    PageTopNav: () => import("@/components/PageTopNav.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      loadingSelect: false,
      configTable: [
        {
          prop: "produce_plan_name",
          label: "计划名称",
          width: "260",
        },
        {
          prop: "product_name",
          label: "产品",
          width: "260",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "260",
        },
        {
          prop: "remark",
          label: "备注",
          width: "",
        },
        {
          prop: "",
          label: "操作",
          width: "260",
          delType: true,
          editType: true,
        },
      ],
      productList: [],
      productTotal: 0,
      productPn: 1,
      total: 0, // 总数
      last_page: 1, //末尾页
      per_page: 10, // 一页个数
      current_page: 1, // 当前页
      tabList: [],
      meansList: [],
      addShow: false,
      bigPopTitle: "",
      form: {
        name: "",
        num: "",
        remark: "",
        product_id: "",
      },
      id: "",
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        num: [
          { required: true, message: "请输入计划生产数量", trigger: "blur" },
        ],
        product_id: [
          { required: true, message: "请选择产品", trigger: "change" },
        ],
      },

      formLabelWidth: "80px",
    };
  },
  watch: {
    addShow(newVal) {
      if (!newVal) {
        this.form = {
          name: "",
          num: "",
          remark: "",
          product_id: "",
        };
      }
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getDryPlanList();
    this.getProductList();
  },
  methods: {
    handleDelete({ produce_plan_id }) {
      this.$confirm("此操作将删除该计划, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code } = await delDryPlanApi({ produce_plan_id });
          if (code === 204) {
            this.getDryPlanList();
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    async handleEdit({ produce_plan_id }) {
      let obj =
        this.meansList.find(
          (item) => item.produce_plan_id === produce_plan_id
        ) || {};
      this.form.name = obj.produce_plan_name;
      this.form.num = obj.num;
      this.form.remark = obj.remark;
      this.form.product_id = obj.product_id;
      this.form.produce_plan_id = produce_plan_id;
      this.bigPopTitle = "编辑烘干计划";
      this.addShow = true;
    },
    inputNumber() {
      if (this.form.num <= 0) this.form.num = "";
    },
    async getProductList() {
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
        pn: this.productPn,
      });
      if (code !== 0) return;
      this.productList = [...this.productList, ...results.data];
      this.productPn = results.page_num;
      this.productTotal = results.count;
      this.loadingSelect = false;
    },
    async selectFilter(key_word) {
      if (!key_word) {
        this.productList = [];
        return;
      }
      if (this.productPn == 1) {
        this.productList = [];
      }
      this.loadingSelect = true;
      const { code, results } = await getProductListApi({
        title: key_word,
        manor_id: this.farmID,
        pn: this.productPn,
      });
      if (code !== 0) return;
      this.productList = [...this.productList, ...results.data];
      this.productPn = results.page_num;
      this.productTotal = results.count;
      this.loadingSelect = false;
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    async addDryPlan() {
      const { code } = await postAddDryPlanApi({
        ...this.form,
      });
      if (code === 0) {
        this.getDryPlanList();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
      }
    },
    async editDryPlan() {
      const { code } = await postEditDryPlanApi({
        ...this.form,
      });
      if (code === 0) {
        this.getDryPlanList();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.$refs["form"].resetFields();
      }
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getDryPlanList();
    },
    async getDryPlanList() {
      showFullScreenLoading();
      const { code, results } = await getDryPlanListApi({
        pn: this.current_page,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.page_size;
        this.current_page = results.page_num;
        this.meansList = results.data;
      }
      tryHideFullScreenLoading();
    },

    changeTab() {
      this.current_page = 1;
      this.getDryPlanList();
    },

    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.bigPopTitle === "添加烘干计划") {
            this.addDryPlan();
          } else {
            this.editDryPlan();
          }
          this.addShow = false;
        } else {
          return false;
        }
      });
    },
    // 编辑烘干计划
    editMean() {
      this.bigPopTitle = "编辑烘干计划";
      this.addShow = true;
    },
    // 添加烘干计划
    addMeans() {
      this.bigPopTitle = "添加烘干计划";
      this.addShow = true;
    },

    setAddClose() {
      this.addShow = false;
      this.form = {};
    },
  },
};
</script>

<style lang="less" scoped>
.means {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  .outDiv {
    padding: 0 48px;
    .mains {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      background-color: #0e2138;
      padding: 22px 49px;

      .lists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .big-pop {
    /deep/.el-dialog {
      width: 666px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 662px;
    height: 415px;
    background: url(../../assets/image/land/popBgS.png) no-repeat center center;
    background-size: cover;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;
      align-items: center;
      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }

    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      display: flex;
      padding: 0 62px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  .big-pop {
    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 54px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 666px;
      height: 693px;
      background: url(../../assets/image/land/popBgB.png) no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px;
      overflow: hidden;
      .addForm {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          color: #fff;
          margin-right: 16px;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }

  /deep/.uploadCover {
    width: 158px;
    height: 105px;
    display: flex;
    .el-upload--picture-card {
      width: 100%;
      height: 100%;
      line-height: 105px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 158px;
      height: 105px;
    }
    &.active {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
}
</style>
